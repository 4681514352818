/**
 * #SETTINGS
 */

//$inuit-base-font-size:          16px;
//$inuit-base-line-height:        24px;
//$inuit-base-text-color:         #333;
//$inuit-base-background-color:   #fff;
@import "../../../bower_components/inuit-defaults/settings.defaults";
@import "settings.global";
@import "settings.colors";


@import "../../../bower_components/inuit-responsive-settings/settings.responsive";


/**
 * #TOOLS
 */
@import "../../../bower_components/inuit-functions/tools.functions";
@import "../../../bower_components/inuit-mixins/tools.mixins";


@import "tools.mixins";

@import "../../../bower_components/inuit-responsive-tools/tools.responsive";
@import "tools.aliases";

 




 /**
 * #GENERIC
 */
@import "../../../bower_components/inuit-normalize/generic.normalize";
@import "../../../bower_components/inuit-reset/generic.reset";
@import "../../../bower_components/inuit-box-sizing/generic.box-sizing";
@import "../../../bower_components/inuit-shared/generic.shared";



/**
 * #BASE
 */
@import "../../../bower_components/inuit-page/base.page";
@import "base.page";
@import "base.type";
@import "base.links";

$inuit-heading-size-1:  48px;
$inuit-heading-size-2:  36px;
$inuit-heading-size-3:  24px;
$inuit-heading-size-4:  20px;
$inuit-heading-size-5:  18px;
$inuit-heading-size-6:  14px;

$h1: $inuit-heading-size-1;
$h2: $inuit-heading-size-2;
$h3: $inuit-heading-size-3;
$h4: $inuit-heading-size-4;
$h5: $inuit-heading-size-5;
$h6: $inuit-heading-size-6;

@import "../../../bower_components/inuit-headings/base.headings";

@include headings() {
  font-family: $header-font-family;
  font-weight: $bold;
  text-transform: uppercase;
  margin-bottom: $spacing-unit * 1.5;
  letter-spacing: 1.5px; 
}

h3, h4, h5{
  margin-bottom: $spacing-unit;
}

h3{
    line-height: 1.2;
}

h2{
    @include media-query(palm){
        @include font-size(26px, 1.2);
    }
}


@import "../../../bower_components/inuit-lists/base.lists";
@import "../../../bower_components/inuit-images/base.images";
@import "../../../bower_components/inuit-paragraphs/base.paragraphs";


/* ==========================================================================
   Objects
   ========================================================================== */




/* Block Object
   ========================================================================== */

/* Variables */

$inuit-enable-block--flush:     true;
// $inuit-enable-block--tiny:      false;
 $inuit-enable-block--small:     true;
 $inuit-enable-block--large:     true;
//$inuit-enable-block--huge:      false;
$inuit-enable-block--center:    true;
//$inuit-enable-block--right:     false;

@import "../../../bower_components/inuit-block/objects.block";





/* Box Object
   ========================================================================== */


/* Variables */

 $inuit-enable-box--flush:   true;
 $inuit-enable-box--tiny:    true;
 $inuit-enable-box--small:   true;
 $inuit-enable-box--large:   true;
 $inuit-enable-box--huge:    true;

 $inuit-box-padding--large:     $spacing-unit * 1.5;


@import "../../../bower_components/inuit-box/objects.box";





/* Button Object
   ========================================================================== */

/* Variables */

$inuit-btn-background:      $purple;
//$inuit-btn-color:           #fff;
$inuit-btn-border-width:    0;
//$inuit-btn-border-style:    solid;
//$inuit-btn-border-color:    $inuit-btn-background;
//$inuit-btn-radius:          0;

$inuit-enable-btn--small:   true;
$inuit-enable-btn--large:   true;
//$inuit-enable-btn--full:    false;
//$inuit-enable-btn--pill:    false;

@import "../../../bower_components/inuit-buttons/objects.buttons";

.btn{
    text-transform: uppercase;
    font-weight: $bold;
}


/* Flag Object
   ========================================================================== */

/* Variables */

//$inuit-enable-flag--tiny:       false;
$inuit-enable-flag--small:      true;
//$inuit-enable-flag--large:      false;
//$inuit-enable-flag--huge:       false;
$inuit-enable-flag--rev:          true;
//$inuit-enable-flag--flush:      false;
$inuit-enable-flag--top:        true;
//$inuit-enable-flag--bottom:     false;
$inuit-enable-flag--responsive: true;
//$inuit-flag-collapse-at:        720px;


@import "../../../bower_components/inuit-flag/objects.flag";

/* Layout Object
   ========================================================================== */

/* Variables */

$inuit-enable-layout--tiny:     true; 
$inuit-enable-layout--small:    true; 
$inuit-enable-layout--large:    true;
$inuit-enable-layout--huge:     true;
$inuit-enable-layout--flush:    true;
$inuit-enable-layout--rev:      true;
$inuit-enable-layout--middle:   true;
$inuit-enable-layout--bottom:   true;
$inuit-enable-layout--right:    true;
$inuit-enable-layout--center:   true;
$inuit-enable-layout--auto:     true;

@import "../../../bower_components/inuit-layout/objects.layout";




/* List Bare Object
   ========================================================================== */
@import "../../../bower_components/inuit-list-bare/objects.list-bare";




/* List Block Object
   ========================================================================== */
@import "../../../bower_components/inuit-list-block/objects.list-block";





/* List Inline Object
   ========================================================================== */
@import "../../../bower_components/inuit-list-inline/objects.list-inline";





/* List UI Object
   ========================================================================== */
@import "../../../bower_components/inuit-list-ui/objects.list-ui";






/* Media Object
   ========================================================================== */

/* Variables */

//$inuit-enable-media--tiny:          false;
//$inuit-enable-media--small:         false;
//$inuit-enable-media--large:         false;
//$inuit-enable-media--huge:          false;
//$inuit-enable-media--rev:           false;
//$inuit-enable-media--flush:         false;
//$inuit-enable-media--responsive:    false;
//$inuit-media-collapse-at:           720px;
@import "../../../bower_components/inuit-media/objects.media";





/* Pack Object
   ========================================================================== */

/* Variables */

//$inuit-enable-pack--auto:   false;
//$inuit-enable-pack--tiny:   false;
//$inuit-enable-pack--small:  false;
//$inuit-enable-pack--large:  false;
//$inuit-enable-pack--huge:   false;
//$inuit-enable-pack--rev:    false;
$inuit-enable-pack--middle: true;
//$inuit-enable-pack--bottom: false;

@import "../../../bower_components/inuit-pack/objects.pack";





/* Tables Object
   ========================================================================== */

/* Variables */

//$inuit-table-border-width:      1px;
//$inuit-table-border-style:      solid;
//$inuit-table-border-color:      #ccc;

//$inuit-enable-table--fixed:     false;
//$inuit-enable-table--compact:   false;
//$inuit-enable-table--cosy:      false;
//$inuit-enable-table--comfy:     false;
//$inuit-enable-table--cells:     false;
//$inuit-enable-table--rows:      false;
//$inuit-enable-table--columns:   false;

@import "../../../bower_components/inuit-tables/objects.tables";





/* Tabs Object
   ========================================================================== */
@import "../../../bower_components/inuit-tabs/objects.tabs";




/* Wrapper Object
   ========================================================================== */
@import "objects.wrappers";




/* Inputs Object
   ========================================================================== */

/* Variables */

$inuit-input-background-color:             $white;
//$inuit-input-font-family:                   inherit;
//$inuit-input-text-color:                    $inuit-base-text-color;
$inuit-input-font-size:                     18px;
$inuit-input-border-width:                  2px;
//$inuit-input-border-style:                  solid;
$inuit-input-border-color:                  $grey;
$inuit-input-border-radius:                 0;
//$inuit-input-focus-background-color:        #fff;
//$inuit-input-focus-border-color:            #ccc;
//$inuit-input-disabled-background-color:     #ccc;

//$inuit-label-pointer:                       pointer;
//$inuit-label-font-size:                     $inuit-base-font-size;
$inuit-label-font-weight:                   $regular;
//$inuit-label-line-height:                   $inuit-base-spacing-unit;
$inuit-label-font-color:                    $inuit-base-text-color;
//$inuit-label-bottom-margin:                 0;

@import "objects.inputs";

.input{
    &::placeholder{
        color: $inuit-base-text-color;
    }
}




/* Icon Object
   ========================================================================== */
$inuit-enable-icon--rev:              true;
@import "objects.icon";


/* ==========================================================================
   Components
   ========================================================================== */

@import "slick";

$slick-font-path: "/fonts/" ;
//$slick-font-family: "slick" ;
$slick-loader-path: "/images" ;
$slick-arrow-color: $grey ;
$slick-dot-color: $white ;
//$slick-dot-color-active: $slick-dot-color ;
//$slick-prev-character: "\2190" ;
//$slick-next-character: "\2192" ;
//$slick-dot-character: "\2022" ;
$slick-dot-size: 24px ;
//$slick-opacity-default: 0.75 ;
//$slick-opacity-on-hover: 1 ;
//$slick-opacity-not-active: 0.25 ;

@import "slick-theme";

.i{
   display:inline-block;
}


@import 'components.bands';

.c-header{
    padding: $spacing-unit 0;
    background-color: $purple;
    position: relative;

    @include media-query(lap-and-up){
        padding: $spacing-unit * 2 0;
    }
}

    .c-header__hamburger{
        position:absolute;
        top: 17px;
        right: 0;
        width: 40px;
        height: 40px;
        background-position: 50% 50%;
        display: inline-block;

        z-index:5;

        cursor: pointer;
        @include media-query(lap-and-up){
            display: none;
        }
        
        .c-header__hamburger-icon, .c-header__cross-icon{
            width: 40px;
            height: 40px;
            background-position: 50% 50%;
        }

        .c-header__cross-icon{
            display: none;
        }
        &.is-open{
            .c-header__hamburger-icon{
                display: none;
            }

            .c-header__cross-icon{
                display: inline-block;
            }
        }
    }



    .c-header__text-logo{
        /*color: $white;
        @include font-size(32px, 1);
        margin-bottom: 0;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: $bold;*/

        @media only screen and (max-width: 460px) {
            display: inline-block;
            margin-right: $spacing-unit;
            img{
                width:100%;
                
                height:auto;
            }

        }

        @include media-query(palm){
            img{
                max-width: 300px;
            }
        }
    }

.c-mobile{
    width:100%;
    height: 325px;
    background-color: $brand;
    position:absolute;
    top:0;
    left:0;
    z-index:3;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity .3s ease-in-out;
    @include media-query(lap-and-up){
        display: none;
    }

    &.is-open{
        opacity: 1;
        transform: translateY(0);
    }

}

    .c-mobile__nav{
        list-style: none;
        margin-left: $spacing-unit * 5;
        margin-top: 65px;
    }

        .c-mobile__item{
            @include font-size(14px);
            margin-bottom: $spacing-unit / 4;
        }

        .c-mobile__item--subnav{
            margin-bottom: 0;
            line-height: 1.4;
        }

            .c-mobile__link{
                color: $white;
                &.active{
                    font-weight: $extrabold;
                }
            }

.c-nav{
  text-align: right;
  list-style: none;
  margin-bottom: 0;
  line-height: 1;

  @include media-query(palm){
    display: none;
  }
}

    .c-nav__item{
        display: inline-block;
        margin-left: $spacing-unit / 2;
        position:relative;
        margin-bottom: -$spacing-unit / 3;
        @include font-size(16px, 1);

        @include media-query(lap){
            display: block;
            margin-left: 0;
            margin-bottom: -$spacing-unit / 3;
            &:last-child{
                margin-bottom: 0;
            }
        }

        @include media-query(desk){

             .c-nav__submenu {
                display: none;
                position: absolute;
                left: -15%;
                top: 100%;
                width: 130%;
                margin:  0 !important;
                background: rgba(0,0,0,0.1) !important;
                padding: $spacing-unit / 2;
                z-index: 9;

                &:before {
                    content:"";
                    position: absolute;
                    left: 50%;
                    margin-left: -10px;
                    top: -10px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 10px 10px 10px;
                    border-color: transparent transparent rgba(0,0,0,0.1) transparent;
                    z-index:9999;
                }

                .c-nav__item {
                    display: block;
                    text-align: left;
                    margin-bottom: 8px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .c-nav__link {
                    border-bottom: 1px solid transparent;
                    text-align: left;
                    margin: 0;



                    &:hover {
                        border-bottom: 1px solid white;
                    }
                }

                .c-nav__linkactive {
                    font-weight: bold;
                    color: white;
                }
            }

            &:hover {
                .c-nav__submenu {
                    display: block;
                }
            }
        }

        @include media-query(lap) {

            margin-bottom: 0;

            .c-nav__submenu {
                display: block;
                position: relative;
                background: none;
                padding-bottom: 3px;

                .c-nav__item + .c-nav__item a {
                    padding-top: 0;
                }

                &:before {
                    display:none;
                }

                .c-nav__linkactive {
                    font-weight: bold;
                    color: white;
                }
             }

             .c-nav__link {
                //margin-bottom: 6px;
             }
        }
    }

    
    .c-nav__link{
        display: inline-block;
        color: $white;
        text-decoration: none;
        padding: 3px 0;
        border-bottom: 1px solid transparent;
        margin: $spacing-unit / 3 0;


        &.active{
            font-weight: $extrabold;
        }

        &:hover{
            border-bottom: 1px solid $white;
        }

         @include media-query(lap){
            margin: 0;
         }  
    }

    .c-nav__item--first{
        margin-left: 0;
    }
.c-banner{
    position:relative;
    background-color: $purple;
    padding-bottom: $spacing-unit;
}

    .c-banner__equals{
        display: none;
        margin-bottom: $spacing-unit;
        @include media-query(lap-and-up){
            display: inline-block;
        }
    }

    .c-banner__mobile-equals{
        margin-bottom: $spacing-unit;
        z-index: 5;
        position:relative;
        @include media-query(lap-and-up){
            display: none;
        }
    }

    .c-banner__header{
    	color: $white;
        @include font-size(26px, 1.2);
        margin-bottom: $spacing-unit / 2;
        
        @include media-query(lap-and-up){
            @include font-size(64px);
            margin-bottom: $spacing-unit;
        }
        
    }

    .c-banner__subheader{
        p{
            color: $white;
            font-weight: $bold;
            text-transform: uppercase;
            @include font-size(18px);
            margin-bottom: $spacing-unit / 2;
            @include media-query(lap-and-up){
                @include font-size(28px);
                margin-bottom: $spacing-unit;
            }
        }
    }


.c-content{
    @include media-query(lap-and-up){
        min-height: 500px;
    }
}

.c-lead{
    @include font-size(24px, 1.5);
}

.c-callout{
    background-color: $grey;
}

.c-text{

    ul,ol{
        margin-left: $spacing-unit;
    }

    a{
        font-weight: $bold;
    }

}

    .c-text__lead{
        @include font-size(22px, 1.5);

        @include media-query(lap-and-up){
            @include font-size(24px, 1.5);
        }
    }

.c-text--light{
    color: $white;
    a{
        color: $white;
    }
}

.c-text--lead{
    p:first-of-type{
         @include font-size(24px, 1.5);
    }
}

.c-aside{
    background-color: $grey;
    margin-bottom: $spacing-unit;

    @include media-query(desk){
        margin-bottom: 0;
    }
}
    
    .c-aside__text{
        padding: $spacing-unit * 1.5;
        h3{
            @include font-size(22px ,1.2);
            font-weight: $bold;
            margin-bottom: $spacing-unit;

            @include media-query(lap-and-up){
                @include font-size(26px ,1.2);
            }
        }

        h3:first-of-type{
            margin-bottom: $spacing-unit / 2;
        }

        h4{
            font-weight: $regular;
            margin-bottom: $spacing-unit;
        }

        p{
            margin-bottom: $spacing-unit / 2;
        }
    }


.c-card{
    background-color: $white;
}

    .c-card__header{
        text-transform: none;
        @include font-size(24px);
        margin-bottom: $spacing-unit;
        a{
            color: $inuit-base-text-color;
        }
    }

    .c-card__heading{
        background-color: $grey;
        padding-top:$spacing-unit /2;
        padding-bottom: $spacing-unit /2 ;
    }

    .c-card__body{
        border: 1px solid $grey;
        border-top: none;

    }

    .c-card__link{
        color: $inuit-base-text-color;
    }

    .c-card__title{
        font-weight: $bold;
    }

    .c-card__meta{
        @include font-size(14px);
    }


.c-card--news{

    margin-bottom: $spacing-unit;

    @include media-query(desk){
        margin-bottom: 0;
    }
    
    .c-card__body{
        border:none;
    }

    .c-card__link{
        @include font-size(14px);
    }
}

.c-carousel{

    @include media-query(lap-and-up){
        border: 2px solid $grey;
        padding: $spacing-unit $spacing-unit * 3;   
    }
    

    .slick-prev{
        left: -18px;
        @include media-query(lap-and-up){
            left: $spacing-unit / 2;
        }
       
    }

    .slick-next{
        right: -18px;
        @include media-query(lap-and-up){
            right: $spacing-unit / 2;
        }
       
    }
}

    .c-carousel__item{
        padding-right:$spacing-unit;
        height: 160px;
        //vertical-align: middle;
        &:before{
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
        
    }

        .c-carousel__img{
            max-height: 120px;
            width:auto;
            display: inline-block!important;
            vertical-align: middle!important;

            @include media-query(lap-and-up){
                max-height: 160px;
            }
        }

    .c-carousel__arrow-left, .c-carousel__arrow-right{
        position: absolute;
        top:50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .c-carousel__arrow-left{
        //left: $spacing-unit;
        left: -18px;
        @include media-query(lap-and-up){
            left: $spacing-unit;
        }
    }

    .c-carousel__arrow-right{
        //right: $spacing-unit;
        right: -18px;
        @include media-query(lap-and-up){
            right: $spacing-unit;
        }
    }

.c-newsletter{

}

    .c-newsletter__input{
        border:none;
        //height: 57px;
        

        @include media-query(desk){
            margin-bottom: 0;
        }

        @include media-query(palm){
            margin-bottom: $spacing-unit / 4;
            @include font-size(16px);
        }
    }

    .c-newsletter__btn{
        border:4px solid $white;
        @include font-size(18px, 1);
        height: 54px;

        @include media-query(palm){
            margin-bottom: $spacing-unit;
        }
    }


.c-member{
    border: 1px solid $grey;
}
.c-image{
    margin-bottom: $spacing-unit;

    @include media-query(desk){
        margin-bottom: 0;
    }
}

.c-testimonials{
    
}

.c-testimonial{
    color: $white;
}

	.c-testimonial__quote{
		@include font-size(20px, 1.3);
	}

	.c-testimonial__source{
        @include font-size(22px);
		font-weight: $bold;
		margin-bottom: 0;
	}

    .c-testimonials__pagination{
        position: relative;
    }

        .c-testimonals__quote-marks{
            text-align: center;
            padding-bottom: $spacing-unit * 4;

            @include media-query(palm){
                display: none;
            }
        }

            .c-testimonials__quote-start{
                margin-right: $spacing-unit;
            }

            .c-testimonials__quote-end{
                position: relative;
                top: $spacing-unit * 1.5;
            }

        .c-testimonials__arrow-left{
            position: absolute;
            left:0;
            bottom:0;
            z-index: 3;
            cursor: pointer;
        }

        .c-testimonials__arrow-right{
            position: absolute;
            right:0;
            bottom:0;
            z-index: 3;
            cursor: pointer;
        }

        .c-testimonials__dots{
            bottom: 0;
        }

.c-form{
    margin-bottom: $spacing-unit;
    @include media-query(desk){
        margin-bottom: 0;
    }
}


.c-footer{
    padding: $spacing-unit * 2 0;
    background-color: $brand;
    color: $white;
}

    .c-footer__header{
        @include font-size(18px);
        margin-bottom: $spacing-unit / 2;
    }

    .c-footer__text{
        p{
            @include font-size(14px);
        }
    }

    .c-footer__social{
        margin-bottom: $spacing-unit;
        @include media-query(lap-and-up){
            text-align: right;
            margin-bottom: 0;
        }
    }

        .c-footer__social-link{
            @include font-size(14px);
            color: $white;
            @include media-query(lap-and-up){
                margin-left: $spacing-unit / 2;
            }
        }

    .c-footer__logo{
        margin-bottom: $spacing-unit;
        @include media-query(desk){
            text-align: right;
            margin-bottom: 0;
        }
    }

.c-case-study-item{
    border: 1px solid $grey;
    padding: $spacing-unit * 2 $spacing-unit;
}

    .c-case-study-item__title{
        text-transform: none;
        font-weight: $bold;
    }

    .c-case-study-item__link{
        @include font-size(14px);
    }

.c-list{
    list-style-type: none;
    margin-left: 0;
}
    .c-list__item{
        margin-bottom: $spacing-unit;
    }

.c-list--small{
    .c-list__item{
        margin-bottom: $spacing-unit / 2;
    }
}




.c-sidebar{

}
    .c-sidebar__heading-box{
        padding: $spacing-unit / 2 $spacing-unit * 1.5;
    }

    .c-sidebar__heading{
        color: $white;
    }

    .c-sidebar__body{
        background-color: $grey;
        padding: $spacing-unit $spacing-unit * 1.5;
    }

        .c-sidebar__subheading{
            margin-bottom: $spacing-unit / 2;
        }

        .c-sidebar__list{
            margin-left: $spacing-unit;
        }

        .c-sidebar__item{
            span{
                color: $inuit-base-text-color;
            }
        }

.c-post-item{
    padding-bottom: $spacing-unit;
    border-bottom: 1px solid $grey;
    margin-bottom: $spacing-unit;
}
    .c-post-item__image{
        margin-bottom: $spacing-unit / 2;
    }

    .c-post-item__date{
        @include font-size(14px);
    }

    .c-post-item__tags{
        @include font-size(14px);
    
        @include media-query(lap-and-up){
            text-align: right;
        }
    }

    .c-post-item__tag{
        color: $inuit-base-text-color;
    }

    .c-post-item__title{
        text-transform: none;
        letter-spacing: 0;
        a{
            color: $inuit-base-text-color;
        }
    }

    .c-post-item__link{
        @include font-size(14px);
        color: $inuit-base-text-color;
    }


.c-post{

}


    .c-post__title{
        @include font-size($h3);
        text-transform: none;
        letter-spacing: 0;
    }

.c-pagination{
    position:relative;
}

    .c-pagination__link{
        color: $inuit-base-text-color;
        @include font-size(14px);
        position: relative;
        z-index:4;
        &.is-current{
            color: $magenta;
        }
    }

    .c-pagination__pages{
        position: absolute;
        width: 100%;
        top:50%;
        left:0;
        transform: translateY(-50%);
        text-align: center;
    }

.c-social-box{
    border: 1px solid $white;
    position: relative;
}

    .c-social-box__inner{
        @include media-query(desk){
            position: absolute;
            left:$spacing-unit;
            padding-right: $spacing-unit;
            top:50%;
            transform: translateY(-50%);
        }
        
    }

    .c-social-box__text{
        color: $white;
        font-weight: $bold;
        @include font-size(16px);
        text-transform: uppercase;

        @include media-query(lap-and-up){
            margin-bottom: 0;
        }
        
    }

    .c-social-box__items{
        text-align: left;

        @include media-query(lap){
            text-align: right;
        }

        @include media-query(desk){
            text-align: center;
        }
    }

    .c-social-box__item{
        margin: $spacing-unit / 2 $spacing-unit / 4;
        //margin-left: $spacing-unit / 4;
        //margin-right: $spacing-unit / 4;
    }

    .c-social-box__item--twitter{
        @include media-query(lap-and-up){
            //margin-bottom: $spacing-unit * 2;
        }
    }

    /*.c-social-box__item--linkedin{
        background-size: cover;
        width:112px;
        height:27px;
    }*/

.c-bio{
    border:1px solid $grey;
}

.c-footer-nav{
    list-style: none;
    margin-left: 0
}

    .c-footer-nav__item{
        @include font-size(14px);
    }

        .c-footer-nav__link{
            color: $white;
        }
/*Theme */
@each $name, $color in $colors {

    .page-#{$name}{
        .c-header{
            background-color: $color;
        }

        .c-banner{
            background-color: $color;
        }

        .c-card__heading{
            background-color: $color;
            color: $white;
        }

        .btn{
            background-color: $color;
        }

        .c-case-study-item__link{
            color: $color;
        }

        .c-sidebar__heading-box{
            background-color: $color;
        }

        .c-sidebar__item{
            color: $color;
        }
    }
    
}

.parsley-error{
    border-color: $error!important;
}

.parsley-success{
    border-color: $success!important;
}
.parsley-errors-list{
    margin: 0;
    margin-top: -18px;
    margin-bottom: $spacing-unit;
    list-style: none;
    @include font-size(14px);
    color: $error;
    font-style: italic;
}



/**
 * #TRUMPS
 */
@import "../../../bower_components/inuit-clearfix/trumps.clearfix";
@import "../../../bower_components/inuit-print/trumps.print";

$inuit-enable-margins:                  true;
$inuit-enable-margins--small:            true;
$inuit-enable-margins--large:           true;
$inuit-enable-margins--huge:            true;
$inuit-enable-margins--none:            true;
$inuit-enable-paddings:                 true;
$inuit-enable-paddings--large:          true;
$inuit-enable-paddings--none:           true;
@import "../../../bower_components/inuit-spacing/trumps.spacing";
@import "../../../bower_components/inuit-spacing-responsive/trumps.spacing-responsive";

@import "../../../bower_components/inuit-widths/trumps.widths";
@import "../../../bower_components/inuit-widths-responsive/trumps.widths-responsive";

@import "../../../bower_components/inuit-push/trumps.push";
@import "../../../bower_components/inuit-responsive-push/trumps.push-responsive";

.lap-and-up-pull-1\/2{
   @include media-query(lap-and-up){
      margin-left: -50%;
   }
}


@import "../../../bower_components/inuit-headings-trumps/trumps.headings";
@import "../../../bower_components/inuit-responsive-headings/trumps.headings-responsive";


//Generate background color helper classes
@each $name, $color in $colors {
  .bg-#{$name}{
    background-color: $color!important;
  }
   .#{$name}{
      color: $color!important;
   }
}

.s1{
   @include font-size($h1);
}
.s2{
   @include font-size($h2);
}
.s3{
   @include font-size($h3);
}
.s4{
   @include font-size($h4, 1.3);
}
.s5{
   @include font-size($h5);
}
.s6{
   @include font-size($h6);
}

//link
.link-bare{
   border-bottom:none;
}

.ucase{
   text-transform: uppercase!important;
}

.ncase{
    text-transform: none!important;
}
.lcase{
    text-transform: lowercase!important;
}

.round{
   border-radius: 50%;
}
.align-left{
	text-align: left;
}

.align-center{
	text-align: center;
}

.align-right{
	text-align: right;
}

.align-middle{
   vertical-align: middle;
}

.left{
	float: left;
}

.right{
	float: right;
}

.white{
   color: #fff!important;
}

.bold{
   font-weight: $bold;
}

.regular{
   font-weight: $regular;
}

.light{
   font-weight: $light;
}

.bg-red{
   background-color: red;
}

.palm-hide{
   @include media-query(palm){
      display:none;
   }
}

.lap-and-up-hide{
   @include media-query(lap-and-up){
      display:none;
   }
}

.js-download {
    opacity: 0.5;
}

.js-download.js-download-active {
    opacity: 1;
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 600px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
