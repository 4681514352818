// Page
$inuit-base-background-color:  #fff;
$inuit-base-text-color:   #17313C;

$brand: #17313C;

$white: #fff;
$black: #333;
$grey: #E9EAEC;
$purple: #57407E;
$green: #2BAD70;
$green-dark: #00564b;
$magenta: #cc0070;
$yellow: #D8BE00;
$blue: #36A9E1;
$blue-dark: #142F52;

$error: red;
$success: green;

$colors :(
	white: $white,
	black: $black,
	grey: $grey,
	purple: $purple,
	green: $green,
	green-dark: $green-dark,
	magenta: $magenta,
	yellow: $yellow,
	blue: $blue,
	blue-dark: $blue-dark,
	brand: $brand,
);