/*------------------------------------*\
    #TYPE
\*------------------------------------*/

/**
 * Typographical base selectors.
 */

b,strong{
	font-weight: $bold;
}

p,ul,ol{
	@include font-size(16px);
}

