/*------------------------------------*\
    #BANDS
\*------------------------------------*/

.c-band {
    padding-top:    $spacing-unit;
    padding-bottom: $spacing-unit;

    @include media-query(desk) {
        padding-top:    $spacing-unit--large;
        padding-bottom: $spacing-unit--large;
    }

}

.c-band--small{
    padding-top: $spacing-unit--small;
    padding-bottom: $spacing-unit--small;
}

.c-band--medium{
    padding-top: $spacing-unit * 1.5;
    padding-bottom: $spacing-unit * 1.5;
}

.c-band--large {
    padding-top: $spacing-unit--large;
    padding-bottom: $spacing-unit--large;
}

.c-band--huge {
    padding-top:    $spacing-unit--large;
    padding-bottom: $spacing-unit--large;

    @include media-query(desk) {
        padding-top:    $spacing-unit--large * 2;
        padding-bottom: $spacing-unit--large * 2;
    }
}


.c-band--image{
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
}

.c-band--mountains{
    background-position: 50% bottom;
    background-repeat: no-repeat;
    background-image: url(/images/mountain-transparent.svg);
    background-size: 100% auto;
}

.c-band--pattern-1{
    background-position: 0 0;
    background-repeat: repeat;
    background-image: url(/images/pattern-one.jpg);
}

