/*------------------------------------*\
    #PAGE
\*------------------------------------*/

html {
  	font-family: $font-family;
    font-weight: $regular;
}

body{
	height:100%;
	position:relative;
}

button,
input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
input[type="file"],
input[type="number"],
input[type="tel"],
input[type="submit"],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}