/*------------------------------------*\
    #ICON
\*------------------------------------*/

/**
 * Icon left, text right.
 *
<div class="icon">
    <i class="i i--home"></i>
    <span class="icon__text">Lorem ipsum dolor sit</span>
</div>
 *
 * Define width and height for .i in your stylesheet.
 *
 */
$inuit-icon-spacing:                  halve($inuit-base-spacing-unit) !default;
$inuit-icon-spacing--small:           quarter($inuit-icon-spacing) !default;
$inuit-icon-spacing--large:           $inuit-icon-spacing !default;

$inuit-enable-icon--small:            false !default;
$inuit-enable-icon--large:            false !default;
$inuit-enable-icon--rev:              false !default;





.icon,
%icon{
    display: inline-block;

    .i,
    %i{
        display: inline-block;
        margin-right: $inuit-icon-spacing;
        vertical-align: middle;
    }
}
    .icon__text{
        display: inline-block;
        vertical-align: middle;
    }





@if ($inuit-enable-icon--small == true){

    /**
     * Icons with small gutter.
     */
    .icon--small,
    %icon--small{

        > .i,
        > %i{
            margin-right: $inuit-icon-spacing--small;
        }

        @if ($inuit-enable-icon--rev == true){

            /**
             * Reversed icons with small gutter.
             */
            &.icon--rev,
            &%icon--rev{

                > .i,
                > %i{
                    margin-left: $inuit-icon-spacing--small;
                    margin-right: 0;
                }
            }
        }
    }
}

@if ($inuit-enable-icon--large == true){

    /**
     * Icons with large gutter.
     */
    .icon--large,
    %icon--large{

        > .i,
        > %i{;
            margin-right: $inuit-icon-spacing--large;
        }

        @if ($inuit-enable-icon--rev == true){

            /**
             * Reversed icons with large gutter.
             */
            &.icon--rev,
            &%icon--rev{

                > .i,
                > %i{
                    margin-left: $inuit-icon-spacing--large;
                    margin-right: 0;
                }
            }
        }
    }
}

@if ($inuit-enable-icon--rev == true){

    /**
     * Icon right, text left.
     *
    <div class="icon">
        <span class="icon__text">Lorem ipsum dolor sit</span>
        <i class="i i--home"></i>
    </div>
     *
     */
    .icon--rev,
    %icon--rev{

        > .i,
        > %i{
            margin-left: $inuit-icon-spacing;
            margin-right: 0;
        }
    }
}
