///*------------------------------------*\
//    #MIXINS
//\*------------------------------------*/

// Apply ‘attention’ styles in one go.
@mixin attention($self: false) {

    @if($self != false) {

        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

    @else {

        &:hover,
        &:active,
        &:focus {
            @content;
        }

    }

}

// Generate Helvetica Neue’s numbered font-weights from their aliases.
@mixin font-weight($font-weight) {

    @if ($font-weight == "regular") {
        font-weight: $regular;
    }

    @if ($font-weight == "bold") {
        font-weight: $bold;
    }

}

// Create all specified heading styles in one go.
@mixin headings($from: 1, $to: 6) {

    %base-heading {
        @content;
    }

    @if $from >= 1 and $to <= 6{

        @for $i from $from through $to{

            h#{$i} {
                @extend %base-heading;
            }

        }

    }

}

//Create fallback png for single svg background image
$image-path: '/assets/img' !default;
$fallback-extension: 'png' !default;
@mixin background-image($name, $size:false){
    background-image: url(#{$image-path}/#{$name}.svg);
    @if($size){
        background-size: $size;
    }
    .no-svg &{
        background-image: url(#{$image-path}/#{$name}.#{$fallback-extension});
    }
}





