/*------------------------------------*\
    #INPUTS
\*------------------------------------*/

/**
 * 
 * 
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-input-namespace:                     $inuit-namespace !default;

$inuit-input-spacing:                       $inuit-base-spacing-unit !default;
$inuit-input-spacing--small:                halve($inuit-input-spacing) !default;

$inuit-input-background-color:              #fff !default;
$inuit-input-font-family:                   inherit !default;
$inuit-input-text-color:                    $inuit-base-text-color !default;
$inuit-input-font-size:                     $inuit-base-font-size !default;
$inuit-input-border-width:                  1px !default;
$inuit-input-border-style:                  solid !default;
$inuit-input-border-color:                  #ccc !default;
$inuit-input-border-radius:                 3px !default;
$inuit-input-focus-background-color:        #fff !default;
$inuit-input-focus-border-color:            #ccc !default;
$inuit-input-disabled-background-color:     #ccc !default;

$inuit-label-pointer:                       pointer !default;
$inuit-label-font-size:                     $inuit-base-font-size !default;
$inuit-label-font-weight:                   300 !default;
$inuit-label-line-height:                   $inuit-base-spacing-unit !default;
$inuit-label-font-color:                    $inuit-base-text-color !default;
$inuit-label-bottom-margin:                 0 !default;


$inuit-enable-input--small:         true !default;

.#{$inuit-input-namespace}label,
%#{$inuit-input-namespace}label{
    font-size: $inuit-label-font-size;
    color: $inuit-label-font-color;
    cursor: $inuit-label-pointer;
    display: block;
    font-weight: $inuit-label-font-weight;
    line-height: $inuit-label-line-height;
    margin-bottom: $inuit-label-bottom-margin;
}

.#{$inuit-input-namespace}label--inline,
%#{$inuit-input-namespace}label--inline{
    display: inline-block;
}

.#{$inuit-input-namespace}input,
%#{$inuit-input-namespace}input {
    background-color: $inuit-input-background-color;
    font-family: $inuit-input-font-family;
    border: $inuit-input-border-width $inuit-input-border-style $inuit-input-border-color;
    border-radius: $inuit-input-border-radius;
    color: $inuit-input-text-color;
    display: block;
    font-size: $inuit-input-font-size;
    margin: 0 0 $inuit-input-spacing 0;
    padding: $inuit-input-spacing / 2;
    height: $inuit-input-font-size + ($inuit-input-spacing * 1.5);
    width: 100%;
    // Basic focus styles
    &:focus {
        background: $inuit-input-focus-background-color;
        border-color: $inuit-input-focus-border-color;
        outline: none;
    }

    // Disabled background input background color
    &[disabled], fieldset[disabled] & {
        background-color: $inuit-input-disabled-background-color; 
    }

}

.#{$inuit-pack-namespace}input--textarea,
%#{$inuit-pack-namespace}input--textarea{
    height: 200px;
}

.#{$inuit-input-namespace}input--select,
%#{$inuit-input-namespace}input--select{
    -webkit-appearance: none !important;
    //background-color: $select-bg-color;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iM3B4IiB2aWV3Qm94PSIwIDAgNiAzIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA2IDMiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNS45OTIsMCAyLjk5MiwzIC0wLjAwOCwwICIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: 97% center;
}

@if ($inuit-enable-input--small == true) {
    .#{$inuit-input-namespace}input--small,
    %#{$inuit-input-namespace}input--small {
        margin: 0 0 $inuit-input-spacing--small 0;
        padding: $inuit-input-spacing--small / 2 $inuit-input-spacing--small;
        height: $inuit-input-font-size + ($inuit-input-spacing--small * 1.5);
        
    }

}

