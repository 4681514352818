/*------------------------------------*\
    #PUSH
\*------------------------------------*/

/**
 * A series of helper classes that you can use to push grid elemtents to the
 * right. Classes can take a fraction-like format (e.g. `.push-2/3`)
 * or a spoken-word format (e.g. `.push-two-thirds`). Toggle formats by
 * overriding the `$inuit-use-fractions` variable. Toggle formats by
 * overriding the `$inuit-use-fractions` variable defined below.
 */

// Predefine the variables below in order to alter and enable specific features.
$inuit-push-namespace:  $inuit-namespace !default;
$inuit-use-fractions:   true !default;



[class*="push-"]{ position:relative; }



@if $inuit-use-fractions == true {

    /**
     * Whole.
     */
    [class~="#{$inuit-push-namespace}push-1/1"]       { left: 100% !important; }

    /**
     * Halves.
     */
    [class~="#{$inuit-push-namespace}push-1/2"],
    [class~="#{$inuit-push-namespace}push-2/4"],
    [class~="#{$inuit-push-namespace}push-3/6"],
    [class~="#{$inuit-push-namespace}push-4/8"],
    [class~="#{$inuit-push-namespace}push-5/10"],
    [class~="#{$inuit-push-namespace}push-6/12"]      { left: 50% !important; }

    /**
     * Thirds.
     */
    [class~="#{$inuit-push-namespace}push-1/3"],
    [class~="#{$inuit-push-namespace}push-2/6"],
    [class~="#{$inuit-push-namespace}push-3/9"],
    [class~="#{$inuit-push-namespace}push-4/12"]      { left: 33.3333333% !important; }
    [class~="#{$inuit-push-namespace}push-2/3"],
    [class~="#{$inuit-push-namespace}push-4/6"],
    [class~="#{$inuit-push-namespace}push-6/9"],
    [class~="#{$inuit-push-namespace}push-8/12"]      { left: 66.6666666% !important; }

    /**
     * Quarters.
     */
    [class~="#{$inuit-push-namespace}push-1/4"],
    [class~="#{$inuit-push-namespace}push-2/8"],
    [class~="#{$inuit-push-namespace}push-3/12"]      { left: 25% !important; }
    [class~="#{$inuit-push-namespace}push-3/4"],
    [class~="#{$inuit-push-namespace}push-6/8"],
    [class~="#{$inuit-push-namespace}push-9/12"]      { left: 75% !important; }

    /**
     * Fifths.
     */
    [class~="#{$inuit-push-namespace}push-1/5"],
    [class~="#{$inuit-push-namespace}push-2/10"]      { left: 20% !important; }
    [class~="#{$inuit-push-namespace}push-2/5"],
    [class~="#{$inuit-push-namespace}push-4/10"]      { left: 40% !important; }
    [class~="#{$inuit-push-namespace}push-3/5"],
    [class~="#{$inuit-push-namespace}push-6/10"]      { left: 60% !important; }
    [class~="#{$inuit-push-namespace}push-4/5"],
    [class~="#{$inuit-push-namespace}push-8/10"]      { left: 80% !important; }

    /**
     * Sixths.
     */
    [class~="#{$inuit-push-namespace}push-1/6"],
    [class~="#{$inuit-push-namespace}push-2/12"]      { left: 16.6666666% !important; }
    [class~="#{$inuit-push-namespace}push-5/6"],
    [class~="#{$inuit-push-namespace}push-10/12"]     { left: 83.3333333% !important; }
    /**
     * Eighths.
     */
    [class~="#{$inuit-push-namespace}push-1/8"]       { left: 12.5% !important; }
    [class~="#{$inuit-push-namespace}push-3/8"]       { left: 37.5% !important; }
    [class~="#{$inuit-push-namespace}push-5/8"]       { left: 62.5% !important; }
    [class~="#{$inuit-push-namespace}push-7/8"]       { left: 87.5% !important; }

    /**
     * Ninths.
     */
    [class~="#{$inuit-push-namespace}push-1/9"]       { left: 11.1111111% !important; }
    [class~="#{$inuit-push-namespace}push-2/9"]       { left: 22.2222222% !important; }
    [class~="#{$inuit-push-namespace}push-4/9"]       { left: 44.4444444% !important; }
    [class~="#{$inuit-push-namespace}push-5/9"]       { left: 55.5555555% !important; }
    [class~="#{$inuit-push-namespace}push-7/9"]       { left: 77.7777777% !important; }
    [class~="#{$inuit-push-namespace}push-8/9"]       { left: 88.8888888% !important; }

    /**
     * Tenths.
     */
    [class~="#{$inuit-push-namespace}push-1/10"]      { left: 10% !important; }
    [class~="#{$inuit-push-namespace}push-3/10"]      { left: 30% !important; }
    [class~="#{$inuit-push-namespace}push-7/10"]      { left: 70% !important; }
    [class~="#{$inuit-push-namespace}push-9/10"]      { left: 90% !important; }

    /**
     * Twelfths.
     */
    [class~="#{$inuit-push-namespace}push-1/12"]      { left:  8.3333333% !important; }
    [class~="#{$inuit-push-namespace}push-5/12"]      { left: 41.6666666% !important; }
    [class~="#{$inuit-push-namespace}push-7/12"]      { left: 58.3333333% !important; }
    [class~="#{$inuit-push-namespace}push-11/12"]     { left: 91.6666666% !important; }

} // Close fractions @if.





@else {

    /**
     * Whole.
     */
    .#{$inuit-push-namespace}push-one-whole           { left: 100% !important; }

    /**
     * Halves.
     */
    .#{$inuit-push-namespace}push-one-half,
    .#{$inuit-push-namespace}push-two-quarters,
    .#{$inuit-push-namespace}push-three-sixths,
    .#{$inuit-push-namespace}push-four-eighths,
    .#{$inuit-push-namespace}push-five-tenths,
    .#{$inuit-push-namespace}push-six-twelfths        { left: 50% !important; }

    /**
     * Thirds.
     */
    .#{$inuit-push-namespace}push-one-third,
    .#{$inuit-push-namespace}push-two-sixths,
    .#{$inuit-push-namespace}push-three-ninths,
    .#{$inuit-push-namespace}push-four-twelfths       { left: 33.3333333% !important; }
    .#{$inuit-push-namespace}push-two-thirds,
    .#{$inuit-push-namespace}push-four-sixths,
    .#{$inuit-push-namespace}push-six-ninths,
    .#{$inuit-push-namespace}push-eight-twelfths      { left: 66.6666666% !important; }

    /**
     * Quarters.
     */
    .#{$inuit-push-namespace}push-one-quarter,
    .#{$inuit-push-namespace}push-two-eighths,
    .#{$inuit-push-namespace}push-three-twelfths      { left: 25% !important; }
    .#{$inuit-push-namespace}push-three-quarters,
    .#{$inuit-push-namespace}push-six-eighths,
    .#{$inuit-push-namespace}push-nine-twelfths       { left: 75% !important; }

    /**
     * Fifths.
     */
    .#{$inuit-push-namespace}push-one-fifth,
    .#{$inuit-push-namespace}push-two-tenths          { left: 20% !important; }
    .#{$inuit-push-namespace}push-two-fifths,
    .#{$inuit-push-namespace}push-four-tenths         { left: 40% !important; }
    .#{$inuit-push-namespace}push-three-fifths,
    .#{$inuit-push-namespace}push-six-tenths          { left: 60% !important; }
    .#{$inuit-push-namespace}push-four-fifths,
    .#{$inuit-push-namespace}push-eight-tenths        { left: 80% !important; }

    /**
     * Sixths.
     */
    .#{$inuit-push-namespace}push-one-sixth,
    .#{$inuit-push-namespace}push-two-twelfths        { left: 16.6666666% !important; }
    .#{$inuit-push-namespace}push-five-sixths,
    .#{$inuit-push-namespace}push-ten-twelfths        { left: 83.3333333% !important; }

    /**
     * Eighths.
     */
    .#{$inuit-push-namespace}push-one-eighth          { left: 12.5% !important; }
    .#{$inuit-push-namespace}push-three-eighths       { left: 37.5% !important; }
    .#{$inuit-push-namespace}push-five-eighths        { left: 62.5% !important; }
    .#{$inuit-push-namespace}push-seven-eighths       { left: 87.5% !important; }

    /**
     * Ninths.
     */
    .#{$inuit-push-namespace}push-one-ninth           { left: 11.1111111% !important; }
    .#{$inuit-push-namespace}push-two-ninths          { left: 22.2222222% !important; }
    .#{$inuit-push-namespace}push-four-ninths         { left: 44.4444444% !important; }
    .#{$inuit-push-namespace}push-five-ninths         { left: 55.5555555% !important; }
    .#{$inuit-push-namespace}push-seven-ninths        { left: 77.7777777% !important; }
    .#{$inuit-push-namespace}push-eight-ninths        { left: 88.8888888% !important; }

    /**
     * Tenths.
     */
    .#{$inuit-push-namespace}push-one-tenth           { left: 10% !important; }
    .#{$inuit-push-namespace}push-three-tenths        { left: 30% !important; }
    .#{$inuit-push-namespace}push-seven-tenths        { left: 70% !important; }
    .#{$inuit-push-namespace}push-nine-tenths         { left: 90% !important; }

    /**
     * Twelfths.
     */
    .#{$inuit-push-namespace}push-one-twelfth         { left:  8.3333333% !important; }
    .#{$inuit-push-namespace}push-five-twelfths       { left: 41.6666666% !important; }
    .#{$inuit-push-namespace}push-seven-twelfths      { left: 58.3333333% !important; }
    .#{$inuit-push-namespace}push-eleven-twelfths     { left: 91.6666666% !important; }

} // Close spoken-word @else.
