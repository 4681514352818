/*------------------------------------*\
    #PUSH-RESPONSIVE
\*------------------------------------*/

/**
 * Responsive push classes based on your responsive settings.
 */

@if $inuit-use-fractions == true {

    @each $breakpoint in $breakpoints {

        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // We don’t want to generate responsive push for retina devices.
        @if ($alias != "retina") {

            @include media-query($alias) {

                /**
                 * Whole.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/1"]       { left: 100% !important; }

                /**
                 * Halves.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/2"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/4"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/6"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/8"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-5/10"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-6/12"]      { left: 50% !important; }

                /**
                 * Thirds.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/3"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/6"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/9"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/12"]      { left: 33.3333333% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/3"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/6"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-6/9"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-8/12"]      { left: 66.6666666% !important; }

                /**
                 * Quarters.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/4"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/8"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/12"]      { left: 25% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/4"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-6/8"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-9/12"]      { left: 75% !important; }

                /**
                 * Fifths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/5"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/10"]      { left: 20% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/5"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/10"]      { left: 40% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/5"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-6/10"]      { left: 60% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/5"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-8/10"]      { left: 80% !important; }

                /**
                 * Sixths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/6"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/12"]      { left: 16.6666666% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-5/6"],
                [class~="#{$inuit-push-namespace}#{$alias}-push-10/12"]     { left: 83.3333333% !important; }
                /**
                 * Eighths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/8"]       { left: 12.5% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/8"]       { left: 37.5% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-5/8"]       { left: 62.5% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-7/8"]       { left: 87.5% !important; }

                /**
                 * Ninths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/9"]       { left: 11.1111111% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-2/9"]       { left: 22.2222222% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-4/9"]       { left: 44.4444444% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-5/9"]       { left: 55.5555555% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-7/9"]       { left: 77.7777777% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-8/9"]       { left: 88.8888888% !important; }

                /**
                 * Tenths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/10"]      { left: 10% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-3/10"]      { left: 30% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-7/10"]      { left: 70% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-9/10"]      { left: 90% !important; }

                /**
                 * Twelfths.
                 */
                [class~="#{$inuit-push-namespace}#{$alias}-push-1/12"]      { left:  8.3333333% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-5/12"]      { left: 41.6666666% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-7/12"]      { left: 58.3333333% !important; }
                [class~="#{$inuit-push-namespace}#{$alias}-push-11/12"]     { left: 91.6666666% !important; }

            } // Close media query.

        } // Close retina check.

    } // Close breakpoints loop.

} // Close fractions @if.





@else {

    @each $breakpoint in $breakpoints {

        $alias:     nth($breakpoint, 1);
        $condition: nth($breakpoint, 2);

        // We don’t want to generate responsive push for retina devices.
        @if ($alias != retina) {

            @include media-query($alias) {

                /**
                 * Whole.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-whole           { left: 100% !important; }

                /**
                 * Halves.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-half,
                .#{$inuit-push-namespace}#{$alias}-push-two-quarters,
                .#{$inuit-push-namespace}#{$alias}-push-three-sixths,
                .#{$inuit-push-namespace}#{$alias}-push-four-eighths,
                .#{$inuit-push-namespace}#{$alias}-push-five-tenths,
                .#{$inuit-push-namespace}#{$alias}-push-six-twelfths        { left: 50% !important; }

                /**
                 * Thirds.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-third,
                .#{$inuit-push-namespace}#{$alias}-push-two-sixths,
                .#{$inuit-push-namespace}#{$alias}-push-three-ninths,
                .#{$inuit-push-namespace}#{$alias}-push-four-twelfths       { left: 33.3333333% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-two-thirds,
                .#{$inuit-push-namespace}#{$alias}-push-four-sixths,
                .#{$inuit-push-namespace}#{$alias}-push-six-ninths,
                .#{$inuit-push-namespace}#{$alias}-push-eight-twelfths      { left: 66.6666666% !important; }

                /**
                 * Quarters.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-quarter,
                .#{$inuit-push-namespace}#{$alias}-push-two-eighths,
                .#{$inuit-push-namespace}#{$alias}-push-three-twelfths      { left: 25% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-three-quarters,
                .#{$inuit-push-namespace}#{$alias}-push-six-eighths,
                .#{$inuit-push-namespace}#{$alias}-push-nine-twelfths       { left: 75% !important; }

                /**
                 * Fifths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-fifth,
                .#{$inuit-push-namespace}#{$alias}-push-two-tenths          { left: 20% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-two-fifths,
                .#{$inuit-push-namespace}#{$alias}-push-four-tenths         { left: 40% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-three-fifths,
                .#{$inuit-push-namespace}#{$alias}-push-six-tenths          { left: 60% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-four-fifths,
                .#{$inuit-push-namespace}#{$alias}-push-eight-tenths        { left: 80% !important; }

                /**
                 * Sixths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-sixth,
                .#{$inuit-push-namespace}#{$alias}-push-two-twelfths        { left: 16.6666666% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-five-sixths,
                .#{$inuit-push-namespace}#{$alias}-push-ten-twelfths        { left: 83.3333333% !important; }

                /**
                 * Eighths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-eighth          { left: 12.5% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-three-eighths       { left: 37.5% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-five-eighths        { left: 62.5% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-seven-eighths       { left: 87.5% !important; }

                /**
                 * Ninths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-ninth           { left: 11.1111111% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-two-ninths          { left: 22.2222222% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-four-ninths         { left: 44.4444444% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-five-ninths         { left: 55.5555555% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-seven-ninths        { left: 77.7777777% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-eight-ninths        { left: 88.8888888% !important; }

                /**
                 * Tenths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-tenth           { left: 10% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-three-tenths        { left: 30% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-seven-tenths        { left: 70% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-nine-tenths         { left: 90% !important; }

                /**
                 * Twelfths.
                 */
                .#{$inuit-push-namespace}#{$alias}-push-one-twelfth         { left:  8.3333333% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-five-twelfths       { left: 41.6666666% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-seven-twelfths      { left: 58.3333333% !important; }
                .#{$inuit-push-namespace}#{$alias}-push-eleven-twelfths     { left: 91.6666666% !important; }

            } // Close media query.

        } // Close retina check.

    } // Close breakpoints loop.

} // Close spoken-word @else.
