/*------------------------------------*\
    #WRAPPERS
\*------------------------------------*/

.wrapper {
    max-width: $wrapper-width;
    margin: 0 auto;
    padding-right: $spacing-unit;
    padding-left:  $spacing-unit;

    @include media-query(lap-and-up) {
        padding-right: $spacing-unit;
        padding-left:  $spacing-unit;
    }

}

.lap-and-up-wrapper{
	@include media-query(lap-and-up){
		max-width: $wrapper-width;
    	margin: 0 auto;
    	padding-right: $spacing-unit;
        padding-left:  $spacing-unit;
	}
}
